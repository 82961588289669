/* General styling for the About Us container */
.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; /* Reduced padding for smaller screens */
  background-color: transparent;
  min-height: 100vh;
}

/* Department Logo */
.logo-container {
  margin-bottom: 1.5rem; /* Reduced margin for smaller screens */
}

.department-logo {
  width: 60%; /* Larger percentage for better visibility on small screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* About the Department */
.department-info {
  margin-top: -40px;
  text-align: center;
  max-width: 90%; /* Adjusted for smaller screens */
  margin-bottom: 1.5rem; /* Reduced margin for smaller screens */
}

.department-info h2 {
  font-size: 2rem; /* Smaller font size for mobile */
  margin-bottom: 0.8rem; /* Reduced margin for smaller screens */
  color: white;
}

.department-info p {
  font-size: 1rem; /* Smaller font size for mobile */
  color: white;
  line-height: 1.5; /* Adjusted line height for better readability */
  margin-bottom: 1rem; /* Reduced margin for smaller screens */
}

/* Office Barriers */
.office-barriers {
  background-color: transparent;
  padding: 1rem; /* Reduced padding for smaller screens */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90%; /* Adjusted for smaller screens */
  width: 100%;
  overflow: hidden; /* Hide overflow to create a seamless sliding effect */
}

.office-barriers h3 {
  font-size: 1.8rem; /* Smaller font size for mobile */
  margin-bottom: 0.8rem; /* Reduced margin for smaller screens */
  color: white;
}

.barriers-image-container {
  width: 100%;
  overflow: hidden; /* Hide overflow to create a seamless sliding effect */
}

.barriers-image-wrapper {
  display: flex;
  width: max-content; /* Allow the wrapper to expand based on content */
  animation: slide 20s linear infinite; /* Adjust the time as needed */
}

.barriers-image {
  width: 150px; /* Fixed width for images */
  height: 150px; /* Fixed height for images */
  object-fit: cover; /* Ensure images cover the area without distortion */
  border-radius: 8px;
  margin-right: 10px; /* Add spacing between images */
}

.Barrier {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Barrier h4 {
  font-size: 0.9rem; /* Smaller font size for very small screens */
  color: white;
  margin-top: 5px;
}

/* Keyframes for sliding animation */
@keyframes slide {
  0% {
    transform: translateX(0); /* Start from the initial position */
  }
 
  100% {
    transform: translateX(-50%); /* Move the container by 50% of its width */
  }
}

/* Mobile-specific adjustments */
@media (max-width: 480px) {
  .about-us-container {
    padding: 0.5rem; /* Further reduced padding for very small screens */
  }

  .department-logo {
    width: 100%; /* Full width for very small screens */
  }

  .department-info h2 {
    font-size: 1.8rem; /* Smaller font size for very small screens */
  }

  .department-info p {
    font-size: 0.9rem; /* Smaller font size for very small screens */
  }

  .office-barriers h3 {
    font-size: 1.6rem; /* Smaller font size for very small screens */
  }

  .barriers-image {
    width: 100px; /* Further reduced width for very small screens */
    height: 100px; /* Further reduced height for very small screens */
  }

  .Barrier h4 {
    font-size: 0.8rem; /* Smaller font size for very small screens */
  }
}