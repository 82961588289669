/* Prevent horizontal scrolling */
html, body {
    overflow-x: hidden; /* Hide horizontal overflow */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    width: 100%; /* Ensure full width */
    box-sizing: border-box; /* Include padding and border in element's total width */
  }
  
  *, *::before, *::after {
    box-sizing: inherit; /* Ensure all elements use border-box */
  }
  
  /* Ensure images and other elements do not exceed the viewport width */
  img, video, iframe, canvas, svg {
    max-width: 100%; /* Prevent overflow */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Container for your content */
  .container {
    width: 100%;
    max-width: 1200px; /* Set a max-width for the content */
    margin: 0 auto; /* Center the container */
    padding: 0 20px; /* Add some padding */
  }
  
  /* Example for header */
  .Header-Container {
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed the viewport */
    padding: 10px 20px; /* Add padding */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  /* Example for other sections */
  .section {
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed the viewport */
    padding: 20px;
    box-sizing: border-box; /* Include padding in width calculation */
  }
