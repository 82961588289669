/* Ensure the page takes full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Layout container */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures full-page height */
  position: relative;
  overflow: hidden; /* Prevents scrollbars caused by the video */
}

/* Video Background */
.video-background {
  position: absolute; /* Position it absolutely to fill the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Keep it behind other content */
  overflow: hidden;
}

.video-background video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Ensures the video covers the entire background */
}

/* Content Area */
.content {
  flex: 1; /* Expands to push footer to the bottom */
  padding: 20px;
  text-align: center;
  color: white;
  position: relative; /* Ensures content appears above the video */
  z-index: 1;
}

/* Header */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 15px;
  z-index: 10;
}

/* Footer (appears at bottom when scrolling ends) */
.footer {
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 15px;
  position: relative; /* Ensures footer appears above the video */
  z-index: 1;
}