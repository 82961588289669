/* General card styling */
.card {
  width: 100%; /* Full width by default */
  max-width: 300px; /* Maximum width for larger screens */
  height: auto; /* Height adjusts based on content */
  aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  /* Add spacing between cards */
  display: flex;
  justify-content: space-between;
  flex-direction: column; /* Stack children vertically */
}

.card:hover {
  transform: scale(1.05); /* Slightly enlarge the card when hovered */
}

/* Image container styling */
.image-container {
  width: 100%;
  height: 100%; /* Ensure the container takes up the full height */
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
}

.card-image {
  width: 100%;
  height: 100%; /* Ensure the image fills the container */
  object-fit: contain; /* Ensure the image is fully visible without cropping */
}

/* Footer styling with the Register button */
.card-footer {
  text-align: center;
  padding: 10px;
  background-color: transparent; /* Optional: Add a background color to the footer */
  width: 100%; /* Ensure the footer spans the full width */
}

.register-button {
  background-color: white; /* Google Form button blue */
  color: #4285F4;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block; /* Ensure the button is centered */
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #357ABD; /* Darker blue on hover */
  color:white;
}

/* Container for cards */
.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line */
  justify-content: center; /* Center cards horizontally */
  /* Add consistent spacing between cards */
   /* Add padding around the container */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
      max-width: 45%; /* Two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .card {
      max-width: 100%; /* One card per row on very small screens */
  }
}