.events-page {
    text-align: center;
    padding: 20px;
  }
  
  .event-toggle {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin-bottom: 20px;
  }
  
  .toggle-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button:hover {
    background-color: #0056b3;
  }
  
  .toggle-button:hover .arrow-icon {
    color: #ffcc00; /* Change arrow color on hover */
  }
  
  .arrow-icon {
    font-size: 18px;
    transition: color 0.3s ease; /* Smooth transition for arrow color */
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }