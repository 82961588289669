/* Container for all cards */
.container {
    display: flex; /* Set display to flex to align items horizontally */
    flex-direction: row; /* This is the default, but explicitly setting it for clarity */
    justify-content: space-between; /* Align cards starting from the left */
    align-items: center;
    gap: 20px; /* Space between cards */
    flex-wrap: wrap; /* Allow the cards to wrap to the next line if there's not enough space */
    padding: 20px;
    width: 100%; /* Ensure it takes full width */
  }
  
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .container {
      justify-content: space-between; /* Space cards evenly across the container */
    }
    .container .card {
      width: 80%; /* Cards will take up 100% width on smaller screens */
    }
  }
  